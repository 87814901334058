"use strict";

// console.log('log from example-script.js')
/* 
    This is your main JS file (frontend). Don't rename it. You can split up your script into sepperate files inside the src/javascript/scripts/ folder.
    Vendors will be bundled and prepended before all of your own javascript. Stick your vendor js files inside the src/javascript/vendor/ folder.
    Make sure to declare all the vendors and custom scripts inside the projectConfig.js file.
*/
// console.log('Main JS Loaded');

document.addEventListener('DOMContentLoaded', function () {
    var $header = document.getElementsByClassName("c-site-header")[0];
    var $headerBg = $header.getElementsByClassName("bg")[0];
    var $descriptionsContainer = document.getElementById("menu-description");
    var $descriptions = $descriptionsContainer.getElementsByClassName("c-site-header__description");
    var $page = document.getElementsByClassName("c-page")[0];
    var $searchToggler = document.getElementsByClassName("search-toggler")[0];
    var $menuToggler = document.getElementsByClassName("menu-toggler")[0];
    // var $contactModule = document.getElementsByClassName("c-contact-module")[0];
    var $loadimages = document.getElementsByClassName("load");
    var $mainmenu = document.getElementById("menu-main-menu");
    var subMenus = $header.getElementsByClassName('sub-menu');
    var $keyvisual = document.getElementsByClassName("c-keyvisual")[0];
    var $mobileMenu = document.getElementsByClassName("main-nav-mobile")[0];
    var $topLinkListMobile = $mobileMenu.getElementsByClassName("c-main-nav")[0];
    var $mobileLinks = $topLinkListMobile.getElementsByTagName("li");
    var menuTimer;
    var countTime = 0;

    var _loop = function _loop(_i) {
        if (!$mobileLinks[_i].parentNode.classList.contains('sub-menu')) {
            var $sub = $mobileLinks[_i].getElementsByTagName("ul");
            if ($sub.length > 0) {
                $sub[0].classList.add('m-theme--lightgrey');
                var submenuToggler = document.createElement('div');
                var arrow = document.createTextNode('›');
                submenuToggler.classList.add('toggle');
                submenuToggler.appendChild(arrow);
                $mobileLinks[_i].appendChild(submenuToggler);
                submenuToggler.onclick = function (e) {
                    if ($mobileLinks[_i].classList.contains('open')) {
                        $mobileLinks[_i].classList.remove('open');
                    } else {
                        $mobileLinks[_i].classList.add('open');
                    }
                };
            }
        }
    };

    for (var _i = 0; _i < $mobileLinks.length; _i++) {
        _loop(_i);
    }

    $menuToggler.onclick = function () {
        if (!document.body.classList.contains('menu-open')) {
            document.body.classList.add('menu-open');
        } else {
            document.body.classList.remove('menu-open');
        }
    };

    $searchToggler.onclick = function () {
        if (!$header.classList.contains('search-open')) {
            $header.getElementsByTagName('input')[0].focus();
            $header.classList.add('search-open');
            $header.classList.remove('submenu-open');
        } else {
            $header.classList.remove('search-open');
        }
    };

    function checkHeader($header) {
        var headerheight = $header.clientHeight;
        if (window.scrollY > headerheight) {
            $header.classList.add('scrolled');
        } else {
            $header.classList.remove('scrolled');
        }
    };

    window.addEventListener("scroll", function () {
        setScrollClasses();
    });
    window.addEventListener("DOMMouseScroll", function () {
        setScrollClasses();
    });

    // add everything scoll in this one
    function setScrollClasses() {
        if ($keyvisual !== undefined) {
            checkHeader($header);
        }
    }

    if ($keyvisual !== undefined) {
        checkHeader($header);
    } else {
        $header.classList.add('scrolled');
    }

    function removeSubmenu() {
        $header.classList.remove('submenu-open');
        for (var _i2 = 0; _i2 < $descriptions.length; _i2++) {
            $descriptions[_i2].style.opacity = 0;
            $descriptions[_i2].style.transform = 'translateY(5px)';
            $descriptions[_i2].style.transition = '.5s ease-in-out 0s';
        }
    }

    // remove submenu class when not on header
    function addEvent(obj, evt, fn) {
        if (obj.addEventListener) {
            obj.addEventListener(evt, fn, false);
        } else if (obj.attachEvent) {
            obj.attachEvent("on" + evt, fn);
        }
    }
    addEvent(document, "mouseout", function (e) {
        e = e ? e : window.event;
        var from = e.relatedTarget || e.toElement;
        if (!from || from.nodeName == "HTML") {
            closeSubMenus();
            removeSubmenu();
        }
    });
    $page.onmouseover = function (event) {
        closeSubMenus();
        removeSubmenu();
    };
    var bgTimer;
    $headerBg.onmouseover = function (event) {
        bgTimer = setTimeout(function () {
            closeSubMenus();
            removeSubmenu();
        }, 1000);
    };
    $headerBg.onmouseout = function (event) {
        clearTimeout(bgTimer);
    };

    // get only first level children
    function getChildNodes(node) {
        var children = new Array();
        for (var _i3 = 0; _i3 < node.childNodes.length; _i3++) {
            if (node.childNodes[_i3].nodeType == 1) {
                children.push(node.childNodes[_i3]);
            }
        }
        return children;
    }

    function closeSubMenus() {
        for (var n = 0; n < subMenus.length; n++) {
            if (subMenus[n] !== undefined) {
                subMenus[n].classList.remove('open');
            }
        }
    }

    // check main menu for links Discover, Collaborate, Settle and Contact
    function createLinkListener() {
        var $listItems = getChildNodes($mainmenu);

        var _loop2 = function _loop2(_i4) {
            $link = $listItems[_i4].getElementsByTagName("A")[0];
            href = $link.getAttribute("href");
            parts = href.split('/');

            parts.pop();
            $location = parts.slice(-1).pop();

            if (parts.length == 3) {
                $link.onmouseover = function (event) {
                    closeSubMenus();
                    $header.classList.remove('submenu-open');
                    for (var _i5 = 0; _i5 < $descriptions.length; _i5++) {
                        $descriptions[_i5].style.opacity = 0;
                        $descriptions[_i5].style.transform = 'translateY(5px)';
                        $descriptions[_i5].style.transition = '.5s ease-in-out 0s';
                    }
                };
            } else {
                var target = $descriptionsContainer.getElementsByClassName($location)[0];
                $link.onmouseover = function (event) {
                    // count till 1
                    $header.classList.add('submenu-open');
                    menuTimer = setTimeout(function () {
                        for (var _i6 = 0; _i6 < $descriptions.length; _i6++) {
                            $descriptions[_i6].style.opacity = 0;
                            $descriptions[_i6].style.transform = 'translateY(5px)';
                            $descriptions[_i6].style.transition = '.5s ease-in-out 0s';
                        }
                        target.style.opacity = 1;
                        target.style.transform = 'translateY(0)';
                        target.style.transition = '.5s ease-in-out 0s';

                        // remove all submenu's before adding the one
                        closeSubMenus();

                        var curSubMenu = $listItems[_i4].getElementsByClassName('sub-menu')[0];
                        if (curSubMenu !== undefined) {
                            curSubMenu.classList.add('open');
                        }
                    }, 300);
                };
                $link.onmouseout = function (event) {
                    clearTimeout(menuTimer);
                };
            }
        };

        for (var _i4 = 0; _i4 < $listItems.length; _i4++) {
            var $link;
            var href;
            var parts;
            var $location;

            _loop2(_i4);
        }
    }
    createLinkListener();

    // Fit Videos
    // -----------------------------------------------------------
    if (document.querySelector('.fitvid-wrap')) {
        (function (window, document, undefined) {
            var iframes = document.querySelectorAll('.fitvid-wrap iframe');
            for (var i = 0; i < iframes.length; i++) {
                var iframe = iframes[i],
                    players = /www.youtube.com|player.vimeo.com/;
                if (iframe.src.search(players) > 0) {
                    var videoRatio;
                    var iframeParent;

                    (function () {
                        videoRatio = iframe.height / iframe.width * 100;

                        iframe.style.position = 'absolute';
                        iframe.style.top = '0';
                        iframe.style.left = '0';
                        iframe.width = '100%';
                        iframe.height = '100%';

                        var wrap = document.createElement('div');
                        wrap.className = 'fit-vid';
                        wrap.style.width = '100%';
                        wrap.style.position = 'relative';
                        wrap.style.paddingTop = videoRatio + '%';
                        iframeParent = iframe.parentNode;

                        iframeParent.insertBefore(wrap, iframe);
                        wrap.appendChild(iframe);

                        window.setTimeout(function () {
                            console.log(wrap);
                            wrap.parentNode.style.opacity = 1;
                        }, 400);
                    })();
                }
            }
        })(window, document);
    }

    // get the elements to base it all on
    var links = document.querySelectorAll('.link-on-page'),
        hh = -160;

    function scrollIt(destination, callback) {

        var duration = 500;
        function linear(t) {
            return 1 - --t * t * t * t;
        }

        var start = window.pageYOffset;
        var startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

        var documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
        var windowHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;
        var destinationOffset = destination.getBoundingClientRect().top + window.scrollY + hh;
        var destinationOffsetToScroll = Math.round(documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset);

        if ('requestAnimationFrame' in window === false) {
            window.scroll(0, destinationOffsetToScroll);
            if (callback) {
                callback();
            }
            return;
        }

        function scroll() {
            var now = 'now' in window.performance ? performance.now() : new Date().getTime();
            var time = Math.min(1, (now - startTime) / duration);
            var timeFunction = linear(time);
            window.scroll(0, Math.ceil(timeFunction * (destinationOffsetToScroll - start) + start));

            if (window.pageYOffset === destinationOffsetToScroll) {
                if (callback) {
                    callback();
                }
                return;
            }

            requestAnimationFrame(scroll);
        }

        scroll();
    }

    function scrollto(content) {
        var el = document.getElementById(content);
        scrollIt(el);
    }

    var forEach = function forEach(array, callback, scope) {
        for (var i = 0; i < array.length; i++) {
            callback.call(scope, i, array[i]);
        }
    };

    forEach(links, function (index, item) {
        var str = item.href;
        var re = /#(.*)/;
        var found = str.match(re);
        item.onclick = function (e) {
            e.preventDefault();
            scrollto(found[1]);
        };
    });

    var $inviewitems = document.getElementsByClassName("o-item-in-view");
    if ($inviewitems.length > 0) {
        for (var _i7 = 0; _i7 < $inviewitems.length; _i7++) {
            var iv = new Iteminview($inviewitems[_i7], {
                row: false
            });
        }
    }

    // lazy loading
    function loadImage($image) {
        if ("IntersectionObserver" in window) {
            var lazyImageObserver = new IntersectionObserver(function (entries, observer) {
                entries.forEach(function (entry) {
                    if (entry.isIntersecting) {
                        var lazyImage = entry.target;
                        lazyImage.src = lazyImage.dataset.src;
                        lazyImage.classList.remove("load");
                        lazyImageObserver.unobserve(lazyImage);
                    }
                });
            });
            lazyImageObserver.observe($image);
        } else {
            // Possibly fall back to a more compatible method here
        }
    };
    if ($loadimages.length > 0) {
        for (var i = 0; i < $loadimages.length; i++) {
            loadImage($loadimages[i]);
        }
    }
});